import { useEffect, useState } from "react";
import { MapContainer, TileLayer, Popup, CircleMarker, WMSTileLayer, LayersControl, LayerGroup, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import { Legend } from "./MapLegend";
import { FuelIcon, IceIcon } from "./Icons";
import { Switch } from "@headlessui/react";
import { isToday } from "../utils/Date";

import "leaflet/dist/leaflet.css";
import "./map.css";

const { BaseLayer } = LayersControl;
const { Overlay } = LayersControl;

export const MapComponent = ({ dataQC, dataON, dataFuel, userInfo }) => {
  const [showTempLegend, setShowTempLegend] = useState(false);
  //const legendElement = useRef();
  const [forceRefresh, setForceRefresh] = useState("initialState");
  const [popupShowFuel, setPopupShowFuel] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    console.log("---- DEBUG MAP.JS ----");
    console.log("Quebec data: ", dataQC);
    console.log("Ontario data: ", dataON);
    console.log("Data fuel: ", dataFuel);
    if (dataON?.length > 0) {
      setForceRefresh("updatedState");
    }
  }, [dataON, dataQC, dataFuel]);

  const defaultPosition = [52.73627011852602, -79.5569678861667];
  const redOptions = { color: "#b91c1c" }; //red-700
  const greenOptions = { color: "#15803d" }; //green-700

  const createClusterCustomIcon = function (cluster) {
    let _class;
    if (forceRefresh === "initialState") {
      _class = "custom-marker-cluster-green";
    }
    if (forceRefresh === "updatedState") {
      _class = "custom-marker-cluster-green";
      for (let airport of dataON) {
        if (airport.station === "YFA") {
          if (airport.status === false || isToday(new Date(airport.createdAt)) === false) {
            _class = "custom-marker-cluster-red";
          }
        }
        if (airport.station === "ZKE") {
          if (airport.status === false || isToday(new Date(airport.createdAt)) === false) {
            _class = "custom-marker-cluster-red";
          }
        }
      }
      let yfaZkeStatus = dataON.filter((v) => {
        return v.station === "YFA" || v.station === "ZKE";
      });

      if ((yfaZkeStatus[0] !== undefined) & (yfaZkeStatus[1] !== undefined)) {
        if (isToday(new Date(yfaZkeStatus[0].createdAt)) & isToday(new Date(yfaZkeStatus[1].createdAt))) {
          if ((yfaZkeStatus[0].status === true && yfaZkeStatus[1].status === false) || (yfaZkeStatus[0].status === false && yfaZkeStatus[1].status === true)) {
            _class = "custom-marker-cluster-red-green";
          }
        } else {
          if (
            (yfaZkeStatus[0].status === true && isToday(new Date(yfaZkeStatus[0].createdAt))) ||
            (yfaZkeStatus[1].status === true && isToday(new Date(yfaZkeStatus[1].createdAt)))
          ) {
            _class = "custom-marker-cluster-red-green";
          }
        }
      }
    }
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: _class,
      iconSize: L.point(33, 33, true),
    });
  };

  const switchCallbackHandler = (v) => {
    setPopupShowFuel((v) => !v);
  };

  return (
    <MapContainer center={defaultPosition} zoom={7} maxZoom={8} minZoom={5} className="w-full h-full z-10">
      <LayersControl>
        <BaseLayer checked name="Open street map">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            //attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </BaseLayer>
        <Overlay checked={false} name="Temperature">
          <TileLayer
            url="https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=a5f80cdd4f9a555892e1d1479d707785"
            eventHandlers={{
              add: () => {
                setShowTempLegend(true);
              },
              remove: () => {
                document.getElementById("Temp").remove();
                setShowTempLegend(false);
              },
            }}
          />
          {showTempLegend ? (
            <div>
              <Legend></Legend>
            </div>
          ) : null}
        </Overlay>
        <Overlay name="Clouds">
          <TileLayer url="https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=a5f80cdd4f9a555892e1d1479d707785" />
        </Overlay>
        <Overlay name="Precipitation">
          <TileLayer url="https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=a5f80cdd4f9a555892e1d1479d707785" />
        </Overlay>
        <Overlay name="Radar">
          <WMSTileLayer layers="RADAR_1KM_RRAI" url="https://geo.weather.gc.ca/geomet?" transparent={true} format={"image/png"}></WMSTileLayer>
        </Overlay>
      </LayersControl>
      {/* //TODO yes I know dataQC and dataON should be a single dataDeice and the cluster will take care of the rest... */}
      {dataQC?.map((value, index) => {
        return (
          <LayerGroup>
            {userInfo.admin ? (
              <CircleMarker
                center={value.stationCoordinates}
                pathOptions={
                  // if (dataFuel?.filter((v) => v.station === value.station).length > 0){}
                  // value.status & isToday(new Date(value.createdAt)) & (dataFuel?.filter((v) => v.station === value.station).length > 0)
                  //   ? (dataFuel?.filter((v) => v.station === value.station)[0]?.status === true) &
                  //     isToday(new Date(dataFuel?.filter((v) => v.station === value.station)[0]?.createdAt))
                  //   : true
                  //   ? greenOptions
                  //   : redOptions
                  (() => {
                    if (dataFuel?.filter((v) => v.station === value.station).length === 0) {
                      console.log("Circle only deice", value.station);
                      return value.status & isToday(new Date(value.createdAt)) ? greenOptions : redOptions;
                    } else {
                      console.log("Circle fuel and deice", value.status)
                      return (dataFuel?.filter((v) => v.station === value.station)[0]?.status === true) &
                        isToday(new Date(dataFuel?.filter((v) => v.station === value.station)[0]?.createdAt)) & value.status & isToday(new Date(value.createdAt))
                        ? greenOptions
                        : redOptions;
                    }
                  })()
                }
                radius={10}
                key={value.station}
                eventHandlers={{
                  popupopen: () => {
                    setShowTooltip(false);
                  },
                  popupclose: () => {
                    setShowTooltip(true);
                    setPopupShowFuel(false);
                  },
                }}
              >
                {value.station === "SU2" || value.station === "YKQ" ? (
                  <>
                    {showTooltip && (
                      <Tooltip direction="right" offset={[15, 0]} opacity={0.8} permanent>
                        <div className="flex flex-row mb-2">
                          <FuelIcon
                            color={
                              (dataFuel?.filter((v) => v.station === value.station)[0]?.status === true) &
                              isToday(new Date(dataFuel?.filter((v) => v.station === value.station)[0]?.createdAt))
                                ? greenOptions.color
                                : redOptions.color
                            }
                          />
                          <div className="ml-2">Fuel Status</div>
                        </div>

                        <div className="flex flex-row">
                          <IceIcon
                            color={
                              value.status & //Deice
                              isToday(new Date(value.createdAt))
                                ? greenOptions.color
                                : redOptions.color
                            }
                          />
                          <div className="ml-2">Deice Status</div>
                        </div>
                      </Tooltip>
                    )}{" "}
                  </>
                ) : null}

                <Popup className="w-max" closeButton={false} key={value.station}>
                  <MapStatusComponent
                    stationObject={(() => {
                      //That looks like wtf is going on... Well it is going on motherfu*&^#$
                      if (popupShowFuel) {
                        if (dataFuel?.filter((v) => v.station === value.station)[0]) {
                          return dataFuel?.filter((v) => v.station === value.station)[0];
                        } else {
                          return value;
                        }
                      } else {
                        return value;
                      }
                    })()}
                    station={value.station}
                    userInfo={userInfo}
                    switchCallback={switchCallbackHandler}
                    switchState={popupShowFuel}
                    key={value.station}
                  />
                </Popup>
              </CircleMarker>
            ) : (
              <CircleMarker
                center={value.stationCoordinates}
                pathOptions={value.status & isToday(new Date(value.createdAt)) ? greenOptions : redOptions}
                radius={10}
                key={index}
              >
                <Popup className="w-max" closeButton={false}>
                  <MapStatusComponent stationObject={value} />
                </Popup>
              </CircleMarker>
            )}
          </LayerGroup>
        );
      })}

      <MarkerClusterGroup chunkedLoading maxClusterRadius={50} iconCreateFunction={createClusterCustomIcon} key={forceRefresh}>
        {dataON?.map((value, index) => {
          return (
            <LayerGroup>
              {userInfo.admin ? (
                <CircleMarker
                  center={value.stationCoordinates}
                  pathOptions={(() => {
                    if (dataFuel?.filter((v) => v.station === value.station).length === 0) {
                      // console.log("MOTHERFUCKER", value.station);
                      // console.log(
                      //   "MOtherfucker",
                      //   dataFuel?.filter( (v) => v.station === value.station)
                      // );
                      return value.status & isToday(new Date(value.createdAt)) ? greenOptions : redOptions;
                    } else {
                      return (dataFuel?.filter((v) => v.station === value.station)[0]?.status === true) &
                      isToday(new Date(dataFuel?.filter((v) => v.station === value.station)[0]?.createdAt)) & value.status & isToday(new Date(value.createdAt))
                        ? greenOptions
                        : redOptions;
                    }
                  })()}
                  radius={10}
                  key={value.station}
                  eventHandlers={{
                    popupopen: () => {
                      setShowTooltip(false);
                    },
                    popupclose: () => {
                      setShowTooltip(true);
                      setPopupShowFuel(false);
                    },
                  }}
                >
                  {value.station === "YAT" || value.station === "YMO" ? (
                    <>
                      {showTooltip && (
                        <Tooltip direction="left" offset={[-15, 0]} opacity={0.8} permanent>
                          <div className="flex flex-row mb-2">
                            <FuelIcon
                              color={
                                (dataFuel?.filter((v) => v.station === value.station)[0]?.status === true) &
                                isToday(new Date(dataFuel?.filter((v) => v.station === value.station)[0]?.createdAt))
                                  ? greenOptions.color
                                  : redOptions.color
                              }
                            />
                            <div className="ml-2">Fuel Status</div>
                          </div>

                          <div className="flex flex-row">
                            <IceIcon
                              color={
                                value.status & //Deice
                                isToday(new Date(value.createdAt))
                                  ? greenOptions.color
                                  : redOptions.color
                              }
                            />
                            <div className="ml-2">Deice Status</div>
                          </div>
                        </Tooltip>
                      )}{" "}
                    </>
                  ) : null}

                  <Popup className="w-max" closeButton={false} key={value.station}>
                    <MapStatusComponent
                      stationObject={(() => {
                        //That looks like wtf is going on... Well it is going on motherfu*&^#$
                        if (popupShowFuel) {
                          if (dataFuel?.filter((v) => v.station === value.station)[0]) {
                            return dataFuel?.filter((v) => v.station === value.station)[0];
                          } else {
                            return {
                              status: false,
                              clearBrightTest: "-",
                              waterPasteTest: "-",
                              hoseInspected: "-",
                              cabinetInspected: "-",
                              topStorageTankClean: "-",
                              deadmanServicable: "-",
                              comments: "-",
                              reportedBy: "-",
                              createdAt: undefined,
                            };
                          }
                        } else {
                          return value;
                        }
                      })()}
                      station={value.station}
                      userInfo={userInfo}
                      switchCallback={switchCallbackHandler}
                      key={value.station}
                      switchState={popupShowFuel}
                    />
                  </Popup>
                </CircleMarker>
              ) : (
                <CircleMarker
                  center={value.stationCoordinates}
                  pathOptions={value.status & isToday(new Date(value.createdAt)) ? greenOptions : redOptions}
                  radius={10}
                  key={index}
                >
                  <Popup className="w-max" closeButton={false}>
                    <MapStatusComponent stationObject={value} />
                  </Popup>
                </CircleMarker>
              )}
            </LayerGroup>
          );
        })}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

const MapStatusComponent = ({ stationObject, station, userInfo, switchCallback, switchState }) => {
  // const [switchState, setSwitchState] = useState(false);
  const switchHandler = () => {
    // setSwitchState((v) => !v);
    switchCallback();
  };

  useEffect(() => {
    console.log("-----Debug MapStatusComponent-----");
    console.log(stationObject);
    console.log(switchState);
  });

  return (
    <div className="flex flex-wrap w-56 h-56 md:w-full md:h-full">
      <div className={"w-full flex justify-between items-center px-1 py-1 rounded-lg mb-3 text-gray-300 text-lg font-bold"}>
        <div className="w-[28px]"></div>
        <div className="justify-self-center">{`${stationObject?.station} - ${switchState ? "Fuel" : "Deice"} Status`}</div>
        {userInfo?.admin & (station === "SU2" || station === "YKQ" || station === "YAT" || station === "YMO") ? (
          <Switch
            checked={switchState}
            onChange={switchHandler}
            className={`${switchState ? "bg-gray-500" : "bg-gray-500"} relative inline-flex h-4 w-7 items-center rounded-full`}
          >
            <span className={`${switchState ? "translate-x-4" : "translate-x-1"} inline-block h-2 w-2 transform rounded-full bg-gray-900 transition`} />
          </Switch>
        ) : (
          <div className="w-[28px]"></div>
        )}
      </div>
      <div className="w-full h-28 md:h-full overflow-x-auto bg-card text-gray-300 p-2 rounded-md ">
        {Object?.keys(stationObject)?.map((value, index) => {
          if (value === "_id" || value === "station" || value === "stationCoordinates" || value === "__v" || value === "updatedAt") {
            return null;
          }
          return (
            <div
              className="w-full grid grid-cols-2 gap-4 pb-1 pt-1 items-center border border-line border-b-1 border-t-0 border-l-0 border-r-0 hover:bg-titleBar text-gray-300"
              key={index}
            >
              <div className="col-span-1 place-self-end self-center font-bold whitespace-nowrap">
                {value === "skyjackOperational"
                  ? stationObject.station === "YMT"
                    ? "Truck Operational"
                    : stationObject.station === "YMO"
                    ? "Hotshot Operational"
                    : "Skyjack Operational"
                  : value === "skyjackAccessible"
                  ? stationObject.station === "YMT"
                    ? "Truck Accessible"
                    : stationObject.station === "YMO"
                    ? "Hotshot Accessible"
                    : "Skyjack Accessible"
                  : value === "deiceTankHot"
                  ? "Deice Tank Hot"
                  : value === "deicePumpChecked"
                  ? "Deice Pump Checked"
                  : value === "deiceTankLevel"
                  ? "Deice Tank Level"
                  : value === "skyjackTankLevel"
                  ? stationObject.station === "YMT"
                    ? "Truck Tank Level"
                    : stationObject.station === "YMO"
                    ? "Hotshot Tank Level"
                    : "Skyjack Tank Level"
                  : value === "comments"
                  ? "Comments"
                  : value === "reportedBy"
                  ? "Reported By"
                  : value === "createdAt"
                  ? "Reported At"
                  : value === "fuelLevel"
                  ? "Fuel Level"
                  : value === "status"
                  ? "Status"
                  : value === "clearBrightTest"
                  ? "Clear & Bright tested"
                  : value === "waterPasteTest"
                  ? "Water Paste Tested"
                  : value === "hoseInspected"
                  ? "Hose Inspected"
                  : value === "cabinetInspected"
                  ? "Cabint Inspected"
                  : value === "topStorageTankClean"
                  ? "Tank Access Clean"
                  : value === "deadmanServicable"
                  ? "Deadman Servicable"
                  : value}
              </div>

              <div
                className={
                  stationObject[value] === "Yes"
                    ? "px-2 py-1 rounded-lg text col-span-1 w-fit"
                    : stationObject[value] === "No" ||
                      !isToday(new Date(stationObject[value])) & (value === "createdAt") ||
                      stationObject[value] === "1/4" ||
                      stationObject[value] === false ||
                      (stationObject[value] === true) & !isToday(new Date(stationObject["createdAt"]))
                    ? "px-2 py-1 rounded-lg text-red-700 col-span-1 w-fit font-bold"
                    : stationObject[value] === true
                    ? "px-2 py-1 rounded-lg text-green-700 col-span-1 w-fit font-bold"
                    : "px-2 py-1 rounded-lg text col-span-1 w-fit"
                }
              >
                {value === "createdAt"
                  ? new Date(stationObject[value]).toLocaleString()
                  : stationObject[value] === ""
                  ? "-"
                  : (stationObject[value] === true) & isToday(new Date(stationObject["createdAt"])) & !switchState
                  ? "Operational"
                  : stationObject[value] === false || (stationObject[value] === true) & !isToday(new Date(stationObject["createdAt"])) & !switchState
                  ? "Not operational" //TODO not compliant for fuel if false
                  : (stationObject[value] === true) & isToday(new Date(stationObject["createdAt"])) & switchState
                  ? "Compliant"
                  : stationObject[value] === false || (stationObject[value] === true) & !isToday(new Date(stationObject["createdAt"])) & switchState
                  ? "Not compliant" //TODO not compliant for fuel if false
                  : stationObject[value]}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
