import "./App.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import { ChoiceListbox } from "./Components/Listbox";
import { Card } from "./Components/Card";
import { TopNavBarReporterPage } from "./Components/TopNavBar";
import Loading from "./Components/Loading";
import { DeiceStatusReported, FuelStatusReported } from "./Components/StatusReported";

import { isToday } from "./utils/Date";

const choices1 = {
  answers: ["Select an answer", "Yes", "No"],
  classes: ["w-2 h-2 bg-gray-600 rounded-full", "w-2 h-2 bg-green-600 rounded-full", "w-2 h-2 bg-red-600 rounded-full"],
};
const choices2 = {
  answers: ["Select an answer", "1/4", "1/2", "3/4", "Full"],
  classes: [
    "w-2 h-2 bg-gray-600 rounded-full",
    "w-2 h-2 bg-red-600 rounded-full",
    "w-2 h-2 bg-green-600 rounded-full",
    "w-2 h-2 bg-green-600 rounded-full",
    "w-2 h-2 bg-green-600 rounded-full",
  ],
};

const today = new Date();
export const WEEKLY_CHECK_DAYNUMBER = 3;
export const APP_VERSION = "2024.01.10";

function App() {
  const { state } = useLocation(); //TODO from react-router, not used could be removed
  const [station, setStation] = useState(undefined);
  //Deice
  const [skyjackIsOperational, setSkyjackIsOperational] = useState(undefined);
  const [skyjackIsAccessible, setSkyjackIsAccessible] = useState(undefined);
  const [skyjackTankLevel, setSkyjackTankLevel] = useState(undefined);
  const [deIceTankIsHot, setDeIceTankIsHot] = useState(undefined);
  const [deIceTankLevel, setDeIceTankLevel] = useState(undefined);
  const [deIcePumpIsChecked, setdeIcePumpIsChecked] = useState(undefined);
  const [fuelLevel, setFuelLevel] = useState(undefined);
  const [comments, setComments] = useState("");
  const [reportedBy, setReportedBy] = useState(undefined);
  const [status, setStatus] = useState(undefined); //TODO status should be changed to deiceStatus because fuelStatus will be added soon
  const [deiceStatusReportedToday, setDeiceStatusReportedToday] = useState(false);
  //Fuel
  const [clearBrightTest, setClearBrightTest] = useState(undefined);
  const [waterPasteTest, setWaterPasteTest] = useState(undefined);
  const [hoseInspected, setHoseInspected] = useState(undefined);
  const [cabinetInspected, setCabinetInspected] = useState(undefined);
  const [topStorageTankClean, setTopStorageTankClean] = useState(undefined);
  const [deadmanServicable, setDeadmanServicable] = useState(undefined);
  const [fuelComments, setFuelComments] = useState("");
  const [fuelReportedBy, setFuelReportedBy] = useState(undefined);
  const [fuelStatus, setFuelStatus] = useState(undefined);
  const [fuelStatusReportedToday, setFuelStatusReportedToday] = useState(false);
  //App
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const navigate = useNavigate();

  //Handler deice status
  const skyjackIsOperationalHandler = (v) => {
    setSkyjackIsOperational(v);
  };

  const skyjackIsAccessibleHandler = (v) => {
    setSkyjackIsAccessible(v);
  };

  const skyjackTankLevelHandler = (v) => {
    setSkyjackTankLevel(v);
  };

  const deIceTankIsHotHandler = (v) => {
    setDeIceTankIsHot(v);
  };

  const deIceTankLevelHandler = (v) => {
    setDeIceTankLevel(v);
  };

  const deIcePumpIsCheckedHandler = (v) => {
    setdeIcePumpIsChecked(v);
  };

  const commentsHandler = (e) => {
    setComments(e.target.value);
  };

  const reportedByHandler = (e) => {
    setReportedBy(e.target.value);
  };

  const fuelLevelHandler = (e) => {
    if (e.target.value <= 22000) {
      setFuelLevel(e.target.value);
    }
  };

  //Handler fuel status
  const clearBrightTestHandler = (v) => {
    setClearBrightTest(v);
  };

  const waterPasteTestHandler = (v) => {
    setWaterPasteTest(v);
  };

  const hoseInspectedHandler = (v) => {
    setHoseInspected(v);
  };

  const cabinetInspectedHandler = (v) => {
    setCabinetInspected(v);
  };

  const topStorageTankCleanHandler = (v) => {
    setTopStorageTankClean(v);
  };

  const deadmanServicableHandler = (v) => {
    setDeadmanServicable(v);
  };

  const fuelCommentsHandler = (e) => {
    setFuelComments(e.target.value);
  };

  const fuelReportedByHandler = (e) => {
    setFuelReportedBy(e.target.value);
  };

  //Handler Submit deice
  const submitDeice = async () => {
    if (reportedBy?.length === 0 || reportedBy === undefined) {
      alert("You must enter your name");
      return;
    }
    if (today.getDay() === WEEKLY_CHECK_DAYNUMBER && deIceTankLevel === undefined) {
      alert("You must report the deice tank level");
      return;
    }
    if (today.getDay() === WEEKLY_CHECK_DAYNUMBER && skyjackTankLevel === undefined) {
      alert("You must report the skykack tank level");
      return;
    }
    if (today.getDay() === WEEKLY_CHECK_DAYNUMBER && deIcePumpIsChecked === undefined) {
      alert("You must report if the deice pump has been checked");
      return;
    } else {
      try {
        const response = await axios.post(
          process.env.NODE_ENV === "development" ? "http://localhost:3000/post-status" : `${process.env.REACT_APP_PROD_URL}/post-status`,
          {
            station,
            status: status,
            skyjackOperational: skyjackIsOperational,
            skyjackAccessible: skyjackIsAccessible,
            deiceTankHot: deIceTankIsHot,
            deicePumpChecked: deIcePumpIsChecked,
            deiceTankLevel: deIceTankLevel,
            skyjackTankLevel: skyjackTankLevel,
            fuelLevel: fuelLevel,
            comments: comments,
            reportedBy: reportedBy,
          },
          { withCredentials: true }
        );
        if (response.data.success) {
          console.log("submitted with success");
          setDeiceStatusReportedToday((v) => !v);
          // setModalIsOpen((v) => {
          //   return !v;
          // });
        } else {
          console.log("Error:", response.data.error.message);
          const { data } = await axios.get(
            process.env.NODE_ENV === "development" ? "http://localhost:3000/check-session" : `${process.env.REACT_APP_PROD_URL}/check-session`,
            {
              withCredentials: true,
              headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "0" },
            }
          );
          if (data.isAuthenticated === false) {
            alert("Session has expired, status has not been uploaded, please login again.");
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          } else {
            alert("Backend error, contact an admin.");
            return;
          }
        }
      } catch (error) {
        console.log("Error:", error);
        alert("Internet seems to be down, try again later.");
        return;
      }
    }
  };

  //Handler Submit fuel
  const submitFuel = async () => {
    if (fuelReportedBy?.length === 0 || fuelReportedBy === undefined) {
      alert("You must enter your name");
      return;
    }

    try {
      const response = await axios.post(
        process.env.NODE_ENV === "development" ? "http://localhost:3000/post-fuel-status" : `${process.env.REACT_APP_PROD_URL}/post-fuel-status`,
        {
          station,
          status: fuelStatus,
          clearBrightTest: clearBrightTest,
          waterPasteTest: waterPasteTest,
          hoseInspected: hoseInspected,
          cabinetInspected: cabinetInspected,
          topStorageTankClean: topStorageTankClean,
          deadmanServicable: deadmanServicable,
          comments: fuelComments,
          reportedBy: fuelReportedBy,
        },
        { withCredentials: true }
      );
      if (response.data.success) {
        console.log("submitted with success");
        setFuelStatusReportedToday(true);
      } else {
        console.log("Error:", response.data.error.message);
        const { data } = await axios.get(
          process.env.NODE_ENV === "development" ? "http://localhost:3000/check-session" : `${process.env.REACT_APP_PROD_URL}/check-session`,
          {
            withCredentials: true,
            headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "0" },
          }
        );
        if (data.isAuthenticated === false) {
          alert("Session has expired, status has not been uploaded, please login again.");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          alert("Backend error, contact an admin.");
          return;
        }
      }
    } catch (error) {
      console.log("Error:", error);
      alert("Internet seems to be down, try again later.");
      return;
    }
  };

  //Get deice status
  const getDeiceStatus = async (station) => {
    const { data } = await axios.post(
      process.env.NODE_ENV === "development" ? "http://localhost:3000/get-all-status" : `${process.env.REACT_APP_PROD_URL}/get-all-status`,
      { stations: [station] },
      { withCredentials: true }
    );
    console.log(`Get all status for ${station}`, data);
    if (data.length > 0) {
      if (isToday(new Date(data[0].createdAt))) {
        setDeiceStatusReportedToday(true);
        setSkyjackIsOperational(data[0]?.skyjackOperational);
        setSkyjackIsAccessible(data[0]?.skyjackAccessible);
        setSkyjackTankLevel(data[0]?.skyjackTankLevel);
        setDeIceTankIsHot(data[0]?.deiceTankHot);
        setDeIceTankLevel(data[0]?.deiceTankLevel);
        setdeIcePumpIsChecked(data[0]?.deicePumpChecked);
        setFuelLevel(data[0]?.fuelLevel);
        setComments(data[0]?.comments);
        setReportedBy(data[0]?.reportedBy);
      }
    }
  };

  //Fuel status
  const getFuelStatus = async (station) => {
    const { data } = await axios.post(
      process.env.NODE_ENV === "development" ? "http://localhost:3000/get-all-fuel-status" : `${process.env.REACT_APP_PROD_URL}/get-all-fuel-status`,
      { stations: [station] },
      { withCredentials: true }
    );
    console.log(`Get all fuel status for ${station}`, data);
    if (data.length > 0) {
      if (isToday(new Date(data[0].createdAt))) {
        setFuelStatusReportedToday(true);
        setClearBrightTest(data[0].clearBrightTest);
        setWaterPasteTest(data[0].waterPasteTest);
        setHoseInspected(data[0].hoseInspected);
        setCabinetInspected(data[0].cabinetInspected);
        setTopStorageTankClean(data[0].topStorageTankClean);
        setDeadmanServicable(data[0].deadmanServicable);
        setFuelReportedBy(data[0]?.reportedBy);
      }
    }
  };

  useEffect(() => {
    //IIFE (Immediately Invoked Function Expression)
    (async () => {
      try {
        const { data } = await axios.get(
          process.env.NODE_ENV === "development" ? "http://localhost:3000/check-session" : `${process.env.REACT_APP_PROD_URL}/check-session`,
          {
            withCredentials: true,
            headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "0" },
          }
        );
        console.log("App.js data from axios request :", data);
        if (data.isAuthenticated & data.reporter) {
          setIsLoggedIn(true);
          setStation(data.station);
          if (data.station === "YAT") {
            setFuelLevel(0);
          }
          getDeiceStatus(data.station);
          getFuelStatus(data.station);
        } else if (data.isAuthenticated) {
          navigate("/status");
        } else {
          setIsLoggedIn(false);
          navigate("/login");
        }
      } catch (error) {
        console.log(error);
        setIsLoggedIn(null);
      }
    })();
  }, [navigate]);

  useEffect(() => {
    if (skyjackIsOperational === choices1.answers[1] && skyjackIsAccessible === choices1.answers[1] && deIceTankIsHot === choices1.answers[1]) {
      if (station === "YAT") {
        if (fuelLevel > 7000) {
          setStatus(true);
        } else {
          setStatus(true);
        }
      } else {
        setStatus(true);
      }
    } else {
      setStatus(false);
    }

    //Weekly checks
    if (today.getDay() === WEEKLY_CHECK_DAYNUMBER) {
      if (
        deIcePumpIsChecked === choices1.answers[1] &&
        (deIceTankLevel === choices2.answers[2] || deIceTankLevel === choices2.answers[3] || deIceTankLevel === choices2.answers[4]) &&
        (skyjackTankLevel === choices2.answers[2] || skyjackTankLevel === choices2.answers[3] || skyjackTankLevel === choices2.answers[4])
      ) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    }
  }, [skyjackIsOperational, skyjackIsAccessible, skyjackTankLevel, deIceTankIsHot, deIceTankLevel, deIcePumpIsChecked, fuelLevel, status]);

  //Fuel status
  useEffect(() => {
    if (
      clearBrightTest === choices1.answers[1] &&
      waterPasteTest === choices1.answers[1] &&
      hoseInspected === choices1.answers[1] &&
      cabinetInspected === choices1.answers[1] &&
      topStorageTankClean === choices1.answers[1] &&
      deadmanServicable === choices1.answers[1]
    ) {
      setFuelStatus(true);
    } else {
      setFuelStatus(false);
    }
  }, [clearBrightTest, waterPasteTest, hoseInspected, cabinetInspected, topStorageTankClean, deadmanServicable]);

  useEffect(() => {
    console.log("---- DEBUG APP.JS ----");
    console.log("Data passed from previous page :", state);
    console.log("Station name : ", station);
    console.log("Fuel status", fuelStatus);
    console.log("Deice status", status);
    console.log("Deice status reported today:", deiceStatusReportedToday);
    console.log("Fuel status reported today:", fuelStatusReportedToday);
  }, [state, station, status, fuelStatus, deiceStatusReportedToday, fuelStatusReportedToday]);

  return isLoggedIn === null ? (
    <Loading />
  ) : (
    <div className="grid h-screen md:place-items-center bg-background justify-center pt-20">
      <TopNavBarReporterPage title={`Report for ${station}`} />
      <div className="container mx-auto pt-10 md:pt-0 px-10 md:px-0 bg-background">
        <div
          className={
            station === "SU2" || station === "YKQ" || station === "YAT" || station === "YMO"
              ? "grid grid-cols-1 lg:grid lg:grid-cols-2 gap-10"
              : "grid grid-cols-1 lg:grid lg:grid-cols-1 gap-10"
          }
        >
          {/* DEICE STATUS */}
          <Card
            cardTitle={`Deice status`}
            status={status}
            children={
              <div>
                {deiceStatusReportedToday ? (
                  <div className="flex flex-col">
                    <DeiceStatusReported
                      status={status}
                      station={station}
                      reporter={reportedBy}
                      reportedBy={reportedBy}
                      skyjackOperational={skyjackIsOperational}
                      skyjackAccessible={skyjackIsAccessible}
                      deiceTankHot={deIceTankIsHot}
                      deicePumpChecked={deIcePumpIsChecked}
                      deiceTankLevel={deIceTankLevel}
                      skyjackTankLevel={skyjackTankLevel}
                      comments={comments}
                    />
                    <div className="flex justify-between items-center mt-2">
                      <button
                        className="bg-titleBar rounded-lg text-gray-300 p-2 hover:bg-buttonDark hover:text-white w-full font-bold"
                        onClick={() => {
                          setDeiceStatusReportedToday(false);
                          setSkyjackIsOperational(undefined);
                          setSkyjackIsAccessible(undefined);
                          setSkyjackTankLevel(undefined);
                          setDeIceTankIsHot(undefined);
                          setDeIceTankLevel(undefined);
                          setdeIcePumpIsChecked(undefined);
                          setFuelLevel(undefined);
                          setComments(undefined);
                          setReportedBy(undefined);
                        }}
                      >
                        Resubmit report
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="text-gray-300">
                    <>
                      <div className="flex justify-between items-center pb-3 pl-3 pr-3 border-b border-line">
                        <div className="text-sm md:text-base">
                          {station === "YMT" ? "Truck is operational" : station === "YMO" ? "Hotshot is operational" : "Skyjack is operational"}
                        </div>
                        <ChoiceListbox choices={choices1} callback={skyjackIsOperationalHandler}></ChoiceListbox>
                      </div>

                      <div className="flex justify-between items-center p-3 pl-3 pr-3 border-b border-line">
                        <div className="text-sm md:text-base">
                          {station === "YMT" ? "Truck is accessible" : station === "YMO" ? "Hotshot is accessible" : "Skyjack is accessible"}
                        </div>
                        <ChoiceListbox choices={choices1} callback={skyjackIsAccessibleHandler}></ChoiceListbox>
                      </div>

                      {today.getDay() === WEEKLY_CHECK_DAYNUMBER ? (
                        <div className="flex justify-between items-center p-3 pl-3 pr-3 border-b border-line">
                          <div className="text-sm md:text-base">{station === "YMT" ? "Truck gasoline tank level" : "Skyjack gasoline tank level"}</div>
                          <ChoiceListbox choices={choices2} callback={skyjackTankLevelHandler}></ChoiceListbox>
                        </div>
                      ) : null}

                      {today.getDay() === WEEKLY_CHECK_DAYNUMBER ? (
                        <div className="flex justify-between items-center p-3 border-b border-line">
                          <div className="text-sm md:text-base">De-ice tank level</div>
                          <ChoiceListbox choices={choices2} callback={deIceTankLevelHandler}></ChoiceListbox>
                        </div>
                      ) : null}

                      <div className="flex justify-between items-center p-3 border-b border-line">
                        <div className="text-sm md:text-base">De-ice tank is hot</div>
                        <ChoiceListbox choices={choices1} callback={deIceTankIsHotHandler}></ChoiceListbox>
                      </div>

                      {today.getDay() === WEEKLY_CHECK_DAYNUMBER ? (
                        <div className="flex justify-between items-center p-3 border-b border-line">
                          <div className="text-sm md:text-base">De-ice pump checked</div>
                          <ChoiceListbox choices={choices1} callback={deIcePumpIsCheckedHandler}></ChoiceListbox>
                        </div>
                      ) : null}

                      {station === "YAT" ? (
                        <div className="flex justify-between items-center p-3 border-b border-line">
                          <div className="text-sm md:text-base">Jet Fuel quantity</div>
                          <input type="number" min={0} max={22000} onChange={fuelLevelHandler} value={fuelLevel} className="border rounded-md w-72 p-2" />
                        </div>
                      ) : null}

                      <div className="flex justify-between items-center p-3 border-b border-line">
                        <div className="text-sm md:text-base ">Comments</div>
                        <textarea
                          className="border border-line rounded-lg w-40 sm:w-52 ml-3 p-1 min-h-[60px] shadow-sm bg-titleBar"
                          onChange={commentsHandler}
                        ></textarea>
                      </div>

                      <div className="flex justify-between items-center p-3">
                        <div className="text-sm md:text-base">Reported by</div>
                        <input className="border border-line rounded-lg w-40 sm:w-52 ml-3 p-1 shadow-sm bg-titleBar" onChange={reportedByHandler}></input>
                      </div>

                      <div className="flex justify-between items-center mt-2">
                        <button
                          className="bg-titleBar rounded-lg text-gray-300 font-bold p-2 hover:bg-buttonDark hover:text-white w-full"
                          onClick={submitDeice}
                        >
                          Submit report
                        </button>
                      </div>
                    </>
                  </div>
                )}
              </div>
            }
          />

          {/* FUEL STATUS */}
          {station === "SU2" || station === "YKQ" || station === "YAT" || station === "YMO" ? (
            <Card
              cardTitle={`Fuel status`}
              status={fuelStatus}
              children={
                <div>
                  {fuelStatusReportedToday ? (
                    <div>
                      <FuelStatusReported
                        status={fuelStatus}
                        reporter={fuelReportedBy}
                        station={station}
                        clearBrightTest={clearBrightTest}
                        waterPasteTest={waterPasteTest}
                        hoseInspected={hoseInspected}
                        cabinetInspected={cabinetInspected}
                        topStorageTankClean={topStorageTankClean}
                        deadmanServicable={deadmanServicable}
                        comments={fuelComments}
                        reportedBy={fuelReportedBy}
                      />
                      <div className="flex justify-between items-center mt-2">
                        <button
                          className="bg-titleBar rounded-lg text-gray-300 p-2 hover:bg-buttonDark hover:text-white w-full font-bold"
                          onClick={() => {
                            setFuelStatusReportedToday(false);
                            setClearBrightTest(undefined);
                            setWaterPasteTest(undefined);
                            setHoseInspected(undefined);
                            setCabinetInspected(undefined);
                            setTopStorageTankClean(undefined);
                            setDeadmanServicable(undefined);
                            setFuelComments(undefined);
                            setFuelReportedBy(undefined);
                          }}
                        >
                          Resubmit report
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="text-gray-300 ">
                      <div className="flex justify-between items-center pb-3 pl-3 pr-3 border-b border-line">
                        <div className="mr-4 text-sm md:text-base">Clear and bright completed</div>
                        <ChoiceListbox choices={choices1} callback={clearBrightTestHandler}></ChoiceListbox>
                      </div>
                      <div className="flex justify-between items-center p-3 pl-3 pr-3 border-b border-line">
                        <div className="mr-4 text-sm md:text-base">Water paste tested</div>
                        <ChoiceListbox choices={choices1} callback={waterPasteTestHandler}></ChoiceListbox>
                      </div>
                      <div className="flex justify-between items-center p-3 pl-3 pr-3 border-b border-line">
                        <div className="mr-4 text-sm md:text-base">Hoses inspected</div>
                        <ChoiceListbox choices={choices1} callback={hoseInspectedHandler}></ChoiceListbox>
                      </div>
                      <div className="flex justify-between items-center p-3 pl-3 pr-3 border-b border-line">
                        <div className="mr-4 text-sm md:text-base">Cabinet inspected</div>
                        <ChoiceListbox choices={choices1} callback={cabinetInspectedHandler}></ChoiceListbox>
                      </div>
                      <div className="flex justify-between items-center p-3 pl-3 pr-3 border-b border-line">
                        <div className="mr-4 text-sm md:text-base">Tank access clean pathway</div>
                        <ChoiceListbox choices={choices1} callback={topStorageTankCleanHandler}></ChoiceListbox>
                      </div>
                      <div className="flex justify-between items-center p-3 pl-3 pr-3 border-b border-line">
                        <div className="mr-4 text-sm md:text-base">Deadman is serviceable</div>
                        <ChoiceListbox choices={choices1} callback={deadmanServicableHandler}></ChoiceListbox>
                      </div>
                      <div className="flex justify-between items-center p-3 border-b border-line">
                        <div className="text-sm md:text-base">Comments</div>
                        <textarea
                          className="border border-line bg-titleBar rounded-lg w-40 sm:w-52 p-1 min-h-[60px] shadow-sm"
                          onChange={fuelCommentsHandler}
                        ></textarea>
                      </div>

                      <div className="flex justify-between items-center p-3">
                        <div className="text-sm md:text-base">Reported by</div>
                        <input className="border border-line bg-titleBar rounded-lg w-40 sm:w-52 p-1 shadow-sm" onChange={fuelReportedByHandler}></input>
                      </div>

                      <div className="flex justify-between items-center mt-2">
                        <button className="bg-titleBar rounded-lg text-gray-300 font-bold p-2 hover:bg-buttonDark hover:text-white w-full" onClick={submitFuel}>
                          Submit report
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default App;
