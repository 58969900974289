export const COLORS = {
  green700: "#15803d",
  red700: "#b91c1c",
  gray300: "#d1d5db",
  gray600: "#4b5563",
  gray700: "#374151",
  gray800: "#1f2937",
  titleBar: "rgb(36,54,78)",
  card: "rgb(22,34,48)",
  buttonDark: "rgb(18,25,35)",
  background: "rgb(17,25,36)",
  line: "rgb(34,45,57)",
};
