import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { TableStatus, TableFuelStatus } from "./Table";

export function DeiceStatusModal({ isOpen, closeCallback, dataQC, dataON, dataFuel, userInfo }) {
  const [data, setData] = useState([]);

  const [dataSkyjack, setDateSkyjack] = useState([]);
  const [dataTruck, setDataTruck] = useState([]);
  const [dataHotshot, setDataHotshot] = useState([]);

  useEffect(() => {
    let _data = [];
    if (dataQC) {
      _data = [...dataQC];
      let _dataTruck = _data.filter((v) => {
        return v.station === "YMT";
      });
      setDataTruck(_dataTruck);
    }
    if (dataON) {
      _data = [..._data, ...dataON];
      let _dataHotshot = _data.filter((v) => {
        return v.station === "YMO";
      });
      setDataHotshot(_dataHotshot);
    }
    setData(_data);
    let _dataSkyjack = _data.filter((v) => {
      return (v.station !== "YMO") & (v.station !== "YMT");
    });
    setDateSkyjack(_dataSkyjack);
  }, [dataQC, dataON]);

  //Debug
  useEffect(() => {
    console.log("---- DEBUG MODAL.JS ----");
    console.log("Data Trucks", dataTruck);
    console.log("Data Hotshot", dataHotshot);
    console.log("Data Skyjack", dataSkyjack);
  }, [dataTruck, dataHotshot, dataSkyjack]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeCallback}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mx-auto w-full max-w-screen-2xl max-h-fit rounded-lg bg-card p-1 shadow-xl transition-all relative">
                  {/* h-[540px] */}
                  <div className="p-0 m-0 absolute right-2 top-2">
                    <button className="hover:bg-gray-100" onClick={closeCallback}>
                      <svg aria-hidden="true" className="w-4 h-4" fill="gray" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>

                  <Dialog.Title as="h3" className={"text-2xl text-center text-gray-300 rounded-md p-4 font-semibold"}></Dialog.Title>

                  <div className="overflow-auto h-[450px] max-h-fit min-h-[450px] px-4 resize-y">
                    <TableStatus data={dataSkyjack} type={"Skyjack"} />
                    <TableStatus data={dataTruck} type={"Truck"} />
                    <TableStatus data={dataHotshot} type={"Hotshot"} />
                    <TableFuelStatus data={dataFuel} userInfo={userInfo}/>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
