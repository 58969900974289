import { WEEKLY_CHECK_DAYNUMBER } from "../App";

const TODAY = new Date();

export const DeiceStatusReported = ({
  status,
  reporter,
  station,
  skyjackOperational,
  skyjackAccessible,
  deiceTankHot,
  deicePumpChecked,
  deiceTankLevel,
  skyjackTankLevel,
  comments,
  reportedBy,
}) => {
  const mailOpener = (sendTo) => {
    let email;
    if (sendTo === "plourde") {
      email = "plourded@aircreebec.ca";
    }
    if (sendTo === "clayton") {
      email = "richardc@aircreebec.ca";
    }
    window.open(
      TODAY.getDay() === WEEKLY_CHECK_DAYNUMBER
        ? `mailto:${email}?subject=De-ice not operational in ${station}&body=${station} de-ice status: %0D%0A%0D%0A 
              - Skyjack is operational: ${skyjackOperational}%0D%0A 
              - Skyjack is accessible: ${skyjackAccessible}%0D%0A 
              - Deice tank is hot: ${deiceTankHot}%0D%0A 
              - Deice pump is checked: ${deicePumpChecked}%0D%0A 
              - Deice tank level: ${deiceTankLevel}%0D%0A 
              - Skyjack tank level: ${skyjackTankLevel}%0D%0A 
              - Comments: ${comments}%0D%0A
              - Reported by: ${reportedBy}%0D%0A%0D%0A
              Thank you!`
        : `mailto:${email}?subject=De-ice not operational in ${station}&body=${station} de-ice status: %0D%0A%0D%0A 
              - Skyjack is operational: ${skyjackOperational}%0D%0A 
              - Skyjack is accessible: ${skyjackAccessible}%0D%0A 
              - Deice tank is hot: ${deiceTankHot}%0D%0A 
              - Comments: ${comments}%0D%0A
              - Reported by: ${reportedBy}%0D%0A%0D%0A
              Thank you!`
    );
  };
  return (
    <div className="flex flex-col text-gray-300">
      <div className="rounded-lg">
        {status === undefined || status === false ? (
          <div className="">
            <div className="flex justify-between items-center border-b border-line pb-3 mb-3">
              <div className="text-sm md:text-base font-bold">Status</div>
              <div className="font-bold bg-red-800 text-gray-100 p-1 px-2 rounded-md">{"Not Operational"}</div>
            </div>
            <div className="flex justify-between items-center pb-3 mb-3 border-b border-line">
              <div className="text-sm md:text-base font-bold">Reported By</div>
              <div className="">{reportedBy}</div>
            </div>
            <ul className="bg-card p-2 rounded-md border border-line">
              <div className="font-bold text-center mb-2">You must contact</div>
              <li className="font-bold">Dan Plourde :</li>
              <li className="mb-3 pb-3 border-b border-line" onClick={() => mailOpener("plourde")}>
                ☎️ 705-264-9521 #3115 | 📬 plourded@aircreebec.ca
              </li>
              <li className="font-bold">Richard Clayton :</li>
              <li className="pb-3" onClick={() => mailOpener("clayton")}>
                ☎️ 705-264-9521 #3116 | 📬 richardc@aircreebec.ca
              </li>
            </ul>
          </div>
        ) : (
          <div>
            <div>
              <div className="flex justify-between items-center pb-3 px-3 border-b border-line">
                <div className="text-sm md:text-base font-bold">Status</div>
                <div className="font-bold bg-green-800 text-gray-100 p-1 px-2 rounded-md">{"Operational"}</div>
              </div>
              <div className="flex justify-between items-center p-3 mb-3">
                <div className="text-sm md:text-base font-bold">Reported By</div>
                <div className="">{reportedBy}</div>
              </div>
              <ul className="bg-card p-2 rounded-md border border-line">
                <div className="font-bold text-center mb-2">Support</div>
                <li className="font-bold">Dan Plourde :</li>
                <li className="mb-3 pb-3 border-b border-line" onClick={() => mailOpener("plourde")}>
                  ☎️ 705-264-9521 #3115 | 📬 plourded@aircreebec.ca
                </li>
                <li className="font-bold">Richard Clayton :</li>
                <li className="pb-3" onClick={() => mailOpener("clayton")}>
                  ☎️ 705-264-9521 #3116 | 📬 richardc@aircreebec.ca
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const FuelStatusReported = ({
  status,
  reporter,
  station,
  clearBrightTest,
  waterPasteTest,
  hoseInspected,
  cabinetInspected,
  topStorageTankClean,
  deadmanServicable,
  comments,
  reportedBy,
}) => {
  const mailOpener = (sendTo) => {
    let email;
    if (sendTo === "plourde") {
      email = "plourded@aircreebec.ca";
    }
    if (sendTo === "clayton") {
      email = "richardc@aircreebec.ca";
    }
    window.open(
      `mailto:${email}?subject=Fuel is not operational in ${station}&body=${station} Fuel status: %0D%0A%0D%0A 
              - Clear and bright tested: ${clearBrightTest}%0D%0A 
              - Water paste tested: ${waterPasteTest}%0D%0A 
              - Hose inspected: ${hoseInspected}%0D%0A 
              - Cabinet inspected: ${cabinetInspected}%0D%0A 
              - Top storage tank is clean: ${topStorageTankClean}%0D%0A 
              - Deadman is servicable: ${deadmanServicable}%0D%0A 
              - Comments: ${comments}%0D%0A
              - Reported by: ${reportedBy}%0D%0A%0D%0A
              Thank you!`
    );
  };
  return (
    <div className="flex flex-col text-gray-300">
      <div className=" rounded-lg">
        {status === undefined || status === false ? (
          <div className="">
            <div className="flex justify-between items-center border-b border-line pb-3 mb-3">
              <div className="text-sm md:text-base font-bold">Status</div>
              <div className="font-bold bg-red-800 text-gray-100 p-1 px-2 rounded-md">{"Not Compliant"}</div>
            </div>
            <div className="flex justify-between items-center pb-3 mb-3 border-b border-line">
              <div className="text-sm md:text-base font-bold">Reported By</div>
              <div className="">{reportedBy}</div>
            </div>
            <ul className="bg-card p-2 rounded-md border border-line">
              <div className="font-bold text-center mb-2">You must contact</div>
              <li className="font-bold">Dan Plourde :</li>
              <li className="mb-3 pb-3 border-b border-line" onClick={() => mailOpener("plourde")}>
                ☎️ 705-264-9521 #3115 | 📬 plourded@aircreebec.ca
              </li>
              <li className="font-bold">Richard Clayton :</li>
              <li className="pb-3" onClick={() => mailOpener("clayton")}>
                ☎️ 705-264-9521 #3116 | 📬 richardc@aircreebec.ca
              </li>
            </ul>
          </div>
        ) : (
          <div>
            <div>
              <div className="flex justify-between items-center pb-3 px-3 border-b border-line">
                <div className="text-sm md:text-base font-bold">Status</div>
                <div className="font-bold bg-green-800 text-gray-100 p-1 px-2 rounded-md">{"Operational"}</div>
              </div>
              <div className="flex justify-between items-center p-3 mb-3">
                <div className="text-sm md:text-base font-bold">Reported By</div>
                <div className="">{reportedBy}</div>
              </div>
              <ul className="bg-card p-2 rounded-md border border-line">
                <div className="font-bold text-center mb-2">Support</div>
                <li className="font-bold">Dan Plourde :</li>
                <li className="mb-3 pb-3 border-b border-line" onClick={() => mailOpener("plourde")}>
                  ☎️ 705-264-9521 #3115 | 📬 plourded@aircreebec.ca
                </li>
                <li className="font-bold">Richard Clayton :</li>
                <li className="pb-3" onClick={() => mailOpener("clayton")}>
                  ☎️ 705-264-9521 #3116 | 📬 richardc@aircreebec.ca
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
