import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../Components/Loading";
import { LoadingElement } from "../Components/Loading";
import { Card } from "../Components/Card";
import { TopNavBarStatusPage } from "../Components/TopNavBar";
import { CreatedBy } from "../Components/CreatedBy";
import { add, eachDayOfInterval, subDays } from "date-fns";
import STATIONS from "../utils/airports";
import ChartLine from "../Components/ChartLine";
import "./admin.css";
import { COLORS } from "../utils/Colors";

// const chartColors = [
//   { borderColor: `#003f5c`, backgroundColor: "rgba(0, 63, 92, 0.5)" },
//   { borderColor: `#2f4b7c`, backgroundColor: "rgba(47, 75, 124, 0.5)" },
//   { borderColor: `#665191`, backgroundColor: "rgba(102, 81, 145, 0.5)" },
//   { borderColor: `#a05195`, backgroundColor: "rgba(160, 81, 149, 0.5)" },
//   { borderColor: `#d45087`, backgroundColor: "rgba(212, 80, 135, 0.5)" },
//   { borderColor: `#f95d6a`, backgroundColor: "rgba(249, 93, 106, 0.5)" },
//   { borderColor: `#ff7c43`, backgroundColor: "rgba(255, 124, 67, 0.5)" },
//   { borderColor: `#ffa600`, backgroundColor: "rgba(255, 166, 0, 0.5)" },
//   { borderColor: `#46aaff`, backgroundColor: "rgba(70, 170, 255, 0.5)" },
// ];

const chartColors = [
  { borderColor: `#00B0FF`, backgroundColor: "rgba(0, 176, 255, 0.5)" }, // Bright Sky Blue
  { borderColor: `#32CD32`, backgroundColor: "rgba(50, 205, 50, 0.5)" }, // Crisp Lime Green
  { borderColor: `#FF7F50`, backgroundColor: "rgba(255, 127, 80, 0.5)" }, // Soft Coral
  { borderColor: `#E30B5D`, backgroundColor: "rgba(227, 11, 93, 0.5)" }, // Vivid Raspberry
  { borderColor: `#9966FF`, backgroundColor: "rgba(153, 102, 255, 0.5)" }, // Electric Purple
  { borderColor: `#FFDD00`, backgroundColor: "rgba(255, 221, 0, 0.5)" }, // Sunshine Yellow
  { borderColor: `#008080`, backgroundColor: "rgba(0, 128, 128, 0.5)" }, // Cool Teal
  { borderColor: `#FF9F40`, backgroundColor: "rgba(255, 159, 64, 0.5)" }, // Peachy Orange
  { borderColor: `#C71585`, backgroundColor: "rgba(199, 21, 133, 0.5)" }, // Light Lavender
];

const Admin = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [userInfo, setUserInfo] = useState(undefined);
  const [usersData, setUsersData] = useState([]);
  const [startDate, setStartDate] = useState(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const [daysOfInterval, setDaysOfInterval] = useState([]);
  const [statusesByDate, setStatusesByDate] = useState([]);
  const [statusByDateIsLoading, setStatusByDateIsLoading] = useState(undefined);
  const [statusesByStation, setStatusesByStation] = useState(Array.from({ length: STATIONS.length }, () => []));
  const [statusByStationIsLoading, setStatusByStationIsLoading] = useState(undefined);
  const [chartDataByDate, setChartDataByDate] = useState(undefined);
  const [chartDataByStation, setChartDataByStation] = useState(undefined);
  const [fuelStatusesByDate, setFuelStatusesByDate] = useState([]);

  const chartByDateOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        max: 10,
        ticks: {
          stepSize: 1,
          color: COLORS.gray600,
        },
        grid: {
          color: COLORS.gray600,
        },
        border: {
          color: COLORS.gray600,
        },
      },
      x: {
        ticks: {
          color: COLORS.gray600,
        },
        grid: {
          color: COLORS.gray600,
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: { color: COLORS.gray600 },
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      tooltip: {
        callbacks: {
          footer: (v) => {
            let index = v[0].dataIndex;
            let stringArr = [];
            for (let el of statusesByDate[index]) {
              let str = el.station + ": de-ice status is " + el.status;
              stringArr.push(str);
            }
            //Finding missing stations and indicate not reported
            let missingStations = [];
            for (let refStation of STATIONS) {
              let refStationMissing = true;
              for (let el of statusesByDate[index]) {
                if (el.station === refStation) {
                  refStationMissing = false;
                }
              }
              if (refStationMissing) {
                missingStations.push(refStation);
              }
            }
            for (let el of missingStations) {
              stringArr.push(`${el} de-ice status is not reported`);
            }
            stringArr.push(` `);
            stringArr.push(`Total stations reported : ${statusesByDate[index].length}`);
            return stringArr;
          },
        },
      },
    },
  };

  const chartByStationOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: "category",
        labels: ["De-ice working", "De-ice not working", "Not reported"],
        ticks: {
          color: COLORS.gray600,
        },
        grid: {
          color: COLORS.gray600,
        },
        border: {
          color: COLORS.gray600,
        },
      },
      x: {
        ticks: {
          color: COLORS.gray600,
        },
        grid: {
          color: COLORS.gray600,
        },
      },
    },
    animation: { duration: 500 },
    plugins: {
      legend: {
        position: "bottom",
        labels: { color: COLORS.gray600 },
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      tooltip: {
        callbacks: {
          footer: (v) => {},
        },
      },
    },
  };

  useEffect(() => {
    //IIFE (Immediately Invoked Function Expression)
    (async () => {
      try {
        const { data } = await axios.get(
          process.env.NODE_ENV === "development" ? "http://localhost:3000/check-session" : `${process.env.REACT_APP_PROD_URL}/check-session`,
          {
            withCredentials: true,
            headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "0" },
          }
        );
        if (data.isAuthenticated & data.admin) {
          setIsLoggedIn(true);
          setUserInfo(data);
          try {
            const { data } = await axios.get(
              process.env.NODE_ENV === "development" ? "http://localhost:3000/users" : `${process.env.REACT_APP_PROD_URL}/users`,
              {
                withCredentials: true,
                headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "0" },
              }
            );
            data.users.sort((a, b) => {
              if (a.username < b.username) {
                return -1;
              }
              if (a.username > b.username) {
                return 1;
              }
              return 0;
            });
            setUsersData(data);
          } catch (error) {
            console.log(error);
          }
        } else if (data.isAuthenticated) {
          navigate("/status");
        } else {
          setIsLoggedIn(false);
          navigate("/login");
        }
      } catch (error) {
        console.log(error);
        setIsLoggedIn(null);
      }
    })();
  }, [navigate]);

  //Use to set the date range
  useEffect(() => {
    if (startDate !== undefined && endDate !== undefined) {
      //!Will crash if dates are not selected in chronological order...
      try {
        setDaysOfInterval(eachDayOfInterval({ start: startDate, end: endDate }));
      } catch (error) {
        console.log(error);
      }
    }
  }, [startDate, endDate]);

  //Download statuses by date && statuses by station when daysofinterval is superior to 0
  useEffect(() => {
    const source = axios.CancelToken.source();

    const downloadAndSaveStatuses = async (dateRange) => {
      if (dateRange.length > 0) {
        let _statusesByDate = [];
        let _fuelStatusesByDate = [];
        let _statusesByStation = Array.from({ length: STATIONS.length }, () => []);
        //TODO put this in a try cath so i can catch axios error when cancelling request
        for (let day of dateRange) {
          //Getting statuses by date
          let data = await getStationsHistoryForDate(day, source.token);
          let data1 = await getFuelStatusHistoryForDate(day, source.token);
          _statusesByDate.push(data);
          _fuelStatusesByDate.push(data1);
          //Getting statuses by station
          for (let el of STATIONS) {
            let data = await getStationHistoryForDate(day, el, 1, source.token);
            if (data.length === 0) {
              data = [undefined];
            }
            _statusesByStation[STATIONS.indexOf(el)].push(...data);
          }
        }
        //Adding the missing stations (aka station not reported to statusesByDate)
        for (let date of _statusesByDate) {
          let missingStations = [];
          if (date.length < STATIONS.length) {
            let reportedStations = date.map((v) => {
              return v.station;
            });
            missingStations = STATIONS.filter((item) => !reportedStations.includes(item));
            console.log(missingStations);
          }
          for (let station of missingStations) {
            let dateIndex = _statusesByDate.indexOf(date);
            _statusesByDate[_statusesByDate.indexOf(date)].push({
              station: station,
              status: false,
              comments: "-",
              skyjackOperational: "-",
              skyjackAccessible: "-",
              deiceTankHot: "-",
              reportedBy: "-",
              createdAt: daysOfInterval[dateIndex],
            });
          }
        }

        setStatusesByDate(_statusesByDate);
        setFuelStatusesByDate(_fuelStatusesByDate);
        setStatusesByStation(_statusesByStation);
      }
    };

    downloadAndSaveStatuses(daysOfInterval);
    return () => {
      source.cancel("daysOfInterval changed, canceling ongoing requests.");
    };
  }, [daysOfInterval]);

  //Use to fill up chartDataByDate
  useEffect(() => {
    if (daysOfInterval.length > 0 && statusesByDate.length === daysOfInterval.length) {
      setStatusByDateIsLoading(false);
      setChartDataByDate({
        labels: daysOfInterval.map((v) => v.toLocaleDateString()),
        datasets: [
          {
            label: "Number of station with a positive de-ice status",
            data: statusesByDate.map((stations) => {
              let count = 0;
              //console.log(stations);
              for (let station of stations) {
                if (station.status === true) {
                  count++;
                }
              }
              return count;
            }),
            borderColor: `#00B0FF`,
            backgroundColor: "rgba(0, 176, 255, 0.5)",
            tension: 0.2,
            pointStyle: false,
          },
          {
            data: new Array(statusesByDate.length).fill(STATIONS.length),
            label: "Total number of stations",
            borderColor: `#bc5090`,
            backgroundColor: "#e792bf",
            pointStyle: false,
          },
        ],
      });
    } else if (daysOfInterval.length > 0) {
      setStatusByDateIsLoading(true);
    }
  }, [statusesByDate, daysOfInterval]);

  useEffect(() => {
    if (daysOfInterval.length > 0 && statusesByStation[0].length === daysOfInterval.length) {
      setStatusByStationIsLoading(false);
      setChartDataByStation({
        labels: daysOfInterval.map((v) => v.toLocaleDateString()),
        datasets: STATIONS.map((v, i) => {
          const isDashed = i % 2 === 0; // Example: Make every second line dashed
          const lineStyle = isDashed ? [5, 5] : []; // Define the line style (dashed or solid)
          return {
            label: v,
            data: statusesByStation[i].map((v) => {
              if (v === undefined) {
                return "Not reported";
              }
              if (v.status === true) {
                return "De-ice working";
              }
              if (v.status === false) {
                return "De-ice not working";
              }
              // else {
              //   return v.status.toString();
              // }
            }),
            borderColor: chartColors[i].borderColor,
            backgroundColor: chartColors[i].backgroundColor,
            tension: 0.2,
            pointStyle: false,
            fill: false,
            borderDash: lineStyle, // Apply the line style to the dataset
            hidden: i === 0 ? false : true,
          };
        }),
      });
    } else if (daysOfInterval.length > 0) {
      setStatusByStationIsLoading(true);
    }
  }, [daysOfInterval, statusesByStation]);

  //This function get the deice statuses of all stations for the specified date
  const getStationsHistoryForDate = async (date, cancelToken) => {
    const { data } = await axios.post(
      process.env.NODE_ENV === "development" ? "http://localhost:3000/get-all-status-for-date" : `${process.env.REACT_APP_PROD_URL}/get-all-status-for-date`,
      { stations: STATIONS, date },
      { withCredentials: true, cancelToken: cancelToken }
    );
    return data;
  };

  //This function is a subset of the previous function, getting the deice status of a station for a specific date
  const getStationHistoryForDate = async (date, station, limit, cancelToken) => {
    const { data } = await axios.post(
      process.env.NODE_ENV === "development" ? "http://localhost:3000/get-all-status-for-date" : `${process.env.REACT_APP_PROD_URL}/get-all-status-for-date`,
      { stations: [station], date, limit },
      { withCredentials: true, cancelToken: cancelToken }
    );
    return data;
  };

  //This function get the fuel statuses of all stations for the specified date
  const getFuelStatusHistoryForDate = async (date, cancelToken) => {
    const { data } = await axios.post(
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/get-all-fuel-status-for-date"
        : `${process.env.REACT_APP_PROD_URL}/get-all-fuel-status-for-date`,
      { stations: STATIONS, date },
      { withCredentials: true, cancelToken: cancelToken }
    );
    return data;
  };

  const startDateChangeHandler = (event) => {
    let date = add(new Date(event.target.value), { minutes: new Date(event.target.value).getTimezoneOffset() });
    setStartDate(date);
  };
  const endDateChangeHandler = (event) => {
    let date = add(new Date(event.target.value), { minutes: new Date(event.target.value).getTimezoneOffset() });
    setEndDate(date);
  };

  const lastLoginForUser = (usersData, user) => {
    let loginHistory = usersData.loginHistory;
    console.log("Debuggin lastLoginForUser function", loginHistory);
    for (let el of loginHistory) {
      if (el.user === user) {
        return el;
      }
    }
  };

  //DEBUG
  useEffect(() => {
    console.log("---- DEBUG ----");
    console.log("Statuses by date:", statusesByDate);
    console.log("Status by stations:", statusesByStation);
    console.log("Fuel statuses by date", fuelStatusesByDate);
    console.log("Users:", usersData);
  }, [statusByDateIsLoading, startDate, statusesByDate, fuelStatusesByDate, statusesByStation, usersData]);

  return isLoggedIn === null ? (
    <Loading />
  ) : (
    <div className="bg-background">
      <TopNavBarStatusPage fixed={true} title={"Admin"} userInfo={userInfo} />
      {/* <div className="grid h-screen md:place-items-center bg-gray-100 justify-center pt-20"> */}
      <div className="container mx-auto bg-background p-2 px-10">
        <div className="justify-items-center bg-background pt-20 gap-10">
          {/* <div className="container mx-auto bg-gray-100 p-2"> */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 pb-4">

            <Card
              status={null}
              cardTitle={`Number of stations with working Deice`}
              children={
                <div>
                  <div className="flex flex-row items-center text-gray-300">
                    <div>From</div>
                    <input
                      type="date"
                      onChange={startDateChangeHandler}
                      className="border border-line p-1 mx-1 rounded-md bg-titleBar text-gray-300"
                      value={startDate.toLocaleDateString("en-CA")}
                    />
                    <div>To</div>
                    <input
                      type="date"
                      onChange={endDateChangeHandler}
                      className="border border-line p-1 mx-1 rounded-md bg-titleBar text-gray-300"
                      value={endDate.toLocaleDateString("en-CA")}
                    />
                  </div>
                  {statusByDateIsLoading === undefined ? null : statusByDateIsLoading ? (
                    <LoadingElement height={300} />
                  ) : (
                    <div className="mt-2 h-[300px] w-full">
                      {chartDataByDate === undefined ? null : <ChartLine data={chartDataByDate} options={chartByDateOptions} />}
                    </div>
                  )}
                </div>
              }
            />
            <Card
              status={null}
              cardTitle={`Deice status by station`}
              children={
                <div>
                  {statusByStationIsLoading === undefined ? null : statusByStationIsLoading ? (
                    <LoadingElement height={335} />
                  ) : (
                    <div className="mt-2 h-[335px]">
                      {chartDataByStation === undefined ? null : <ChartLine data={chartDataByStation} options={chartByStationOptions} />}
                    </div>
                  )}
                </div>
              }
            />
          </div>

          {/* De-ice Status by date, by station */}
          <div className="w-full col-span-2 mb-4">
            <Card status={null} cardTitle={"Deice Status by Date & Station"}>
              <div className="h-[600px] overflow-auto">
                <table className=" w-full">
                  <thead>
                    <tr className="text-gray-300">
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Date</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Station</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Skyjack Operational</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Skyjack Accessible</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Tank is Hot</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Deice Pump Checked</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Deice Tank Level</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Skyjack Tank Level</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Fuel Tank Level</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Comments</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Reported By</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Reported At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statusesByDate
                      .slice(0)
                      .reverse()
                      .map((date, index) => {
                        return date.map((status, i) => {
                          return (
                            <tr className={index % 2 === 0 ? "bg-titleBar hover:bg-card" : "bg-titleBar hover:bg-card"} key={Math.random() * 100}>
                              <td className="border text-center border-line text-gray-300 p-2" key={Math.random() * 100}>
                                {new Date(status?.createdAt).toDateString()}
                              </td>
                              <td className="border text-center border-line text-gray-300 p-2" key={Math.random() * 100}>
                                <div
                                  className={
                                    status?.status
                                      ? "w-fit px-2 rounded-lg text-white bg-green-600 font-bold"
                                      : "w-fit px-2 rounded-lg text-white bg-red-600 font-bold"
                                  }
                                >
                                  {status?.station}
                                </div>
                              </td>
                              <td className="border text-center border-line text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.skyjackOperational}
                              </td>
                              <td className="border text-center border-line text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.skyjackAccessible}
                              </td>
                              <td className="border text-center border-line text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.deiceTankHot}
                              </td>
                              <td className="border text-center border-line text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.deicePumpChecked === undefined ? "-" : status?.deicePumpChecked}
                              </td>
                              <td className="border text-center border-line text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.deiceTankLevel === undefined ? "-" : status?.deiceTankLevel}
                              </td>
                              <td className="border text-center border-line text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.skyjackTankLevel === undefined ? "-" : status?.skyjackTankLevel}
                              </td>
                              <td className="border text-center border-line text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.fuelLevel === undefined ? "-" : status?.fuelLevel}
                              </td>
                              <td className="border text-center border-line text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.comments}
                              </td>
                              <td className="border text-center border-line text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.reportedBy}
                              </td>
                              <td className="border text-center border-line text-gray-300 p-2 w-32" key={Math.random() * 100}>
                                {new Date(status?.createdAt).toLocaleTimeString()}
                              </td>
                            </tr>
                          );
                        });
                      })}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>

          {/* Fuel Status by date, by station */}
          <div className="w-full col-span-2 mb-4">
            <Card status={null} cardTitle={"Fuel Status by Date & Station"}>
              <div className="h-[600px] overflow-auto">
                <table className=" w-full">
                  <thead>
                    <tr className="text-gray-300">
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Date</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Station</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Clear & Bright Tested</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Hose Inspected</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Water Paste Tested</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Cabinet Inspected</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Top Storage Tank Clean</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Deadman Servicable</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Comments</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Reported By</th>
                      <th className="border-collapse border-line p-2 sticky top-0 bg-card pb-5">Reported At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fuelStatusesByDate
                      .slice(0)
                      .reverse()
                      .map((date, index) => {
                        return date.map((status, i) => {
                          return (
                            <tr className={index % 2 === 0 ? "bg-titleBar hover:bg-card" : "bg-titleBar hover:bg-card"} key={Math.random() * 100}>
                              <td className="border border-line text-center text-gray-300 p-2" key={Math.random() * 100}>
                                {new Date(status?.createdAt).toDateString()}
                              </td>
                              <td className="border border-line text-center text-gray-300 p-2" key={Math.random() * 100}>
                                <div
                                  className={
                                    status?.status
                                      ? "w-fit px-2 rounded-lg text-white bg-green-600 font-bold"
                                      : "w-fit px-2 rounded-lg text-white bg-red-600 font-bold"
                                  }
                                >
                                  {status?.station}
                                </div>
                              </td>
                              <td className="border border-line text-center text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.clearBrightTest}
                              </td>
                              <td className="border border-line text-center text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.waterPasteTest}
                              </td>
                              <td className="border border-line text-center text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.hoseInspected}
                              </td>
                              <td className="border border-line text-center text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.cabinetInspected}
                              </td>
                              <td className="border border-line text-center text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.topStorageTankClean}
                              </td>
                              <td className="border border-line text-center text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.deadmanServicable}
                              </td>
                              <td className="border border-line text-center text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.comments}
                              </td>
                              <td className="border border-line text-center text-gray-300 p-2" key={Math.random() * 100}>
                                {status?.reportedBy}
                              </td>
                              <td className="border border-line text-center text-gray-300 p-2 w-32" key={Math.random() * 100}>
                                {new Date(status?.createdAt).toLocaleTimeString()}
                              </td>
                            </tr>
                          );
                        });
                      })}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>

          <div className="w-full col-span-2 pb-4">
            <Card status={null} cardTitle={"Users"}>
              <div className="h-[800px] overflow-auto">
                <table className="w-full table-fixed text-gray-300">
                  <thead>
                    <tr>
                      <th className=" p-2 sticky top-0 bg-card pb-5">User</th>
                      <th className=" p-2 sticky top-0 bg-card pb-5">Admin</th>
                      <th className=" p-2 sticky top-0 bg-card pb-5">Super Admin</th>
                      <th className=" p-2 sticky top-0 bg-card pb-5">Last login</th>
                      <th className=" p-2 sticky top-0 bg-card pb-5">IP</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersData.users?.map((user, index) => {
                      return (
                        <tr className={index % 2 === 0 ? "bg-titleBar hover:bg-card" : "bg-titleBar hover:bg-card"} key={Math.random() * 1000}>
                          <td className="border border-line text-center p-2" key={Math.random() * 1000}>
                            {user.username}
                          </td>
                          <td className="border border-line text-center p-2" key={Math.random() * 1000}>
                            {user.admin ? "Yes" : "No"}
                          </td>
                          <td className="border border-line text-center p-2" key={Math.random() * 1000}>
                            {user.superAdmin ? "Yes" : "No"}
                          </td>
                          <td className="border border-line text-center p-2" key={Math.random() * 1000}>
                            {usersData.loginHistory.map((v) => {
                              if (v.user === user.username) {
                                return new Date(v.createdAt).toLocaleString();
                              } else return null;
                            })}
                          </td>
                          <td className="border border-line text-center p-2" key={Math.random() * 1000}>
                            {usersData.loginHistory.map((v) => {
                              if (v.user === user.username) {
                                return (
                                  <a href={`https://iplocation.io/ip/${v.ip}`} target="_blank" rel="noreferrer">
                                    {v.ip}
                                  </a>
                                );
                              } else return null;
                            })}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
          {/* </div> */}
        </div>
        <CreatedBy />
      </div>
    </div>
  );
};

export default Admin;
