import { APP_VERSION } from "../App";

export function CreatedBy() {
  return (
    <a
      href="https://blog.bonneto.ca"
      className="relative inset-x-0 bottom-0 text-gray-600 m-4 flex justify-center"
    >{`Developped by Olivier Bonnet, v.${APP_VERSION}`}</a>
  );
}
