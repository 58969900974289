import { useEffect, useState } from "react";
import { CreatedBy } from "../Components/CreatedBy";
import { MapComponent } from "../Components/Map";
import { DeiceStatusModal } from "../Components/DeiceStatusModal";
import { TopNavBarStatusPage } from "../Components/TopNavBar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isToday } from "../utils/Date";
import Loading from "../Components/Loading";
import { QCstations, ONstations, FuelStations } from "../utils/airports";

function StatusPage() {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [userInfo, setUserInfo] = useState(undefined);
  const [dataQC, setDataQC] = useState(undefined);
  const [dataON, setDataON] = useState(undefined);
  const [dataFuelStatus, setDataFuelStatus] = useState(undefined);

  useEffect(() => {
    // Immediately Invoked Function Expression to check if user is authenticated and to download data.
    (async () => {
      try {
        const { data } = await axios.get(
          process.env.NODE_ENV === "development" ? "http://localhost:3000/check-session" : `${process.env.REACT_APP_PROD_URL}/check-session`,
          {
            withCredentials: true,
            headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "0" },
          }
        );

        if (data.isAuthenticated) {
          setIsLoggedIn(true);
          setUserInfo(data);
          console.log("User info:", data);
          //Downloading QC deice status
          const responseQC = await axios.post(
            process.env.NODE_ENV === "development" ? "http://localhost:3000/get-all-status" : `${process.env.REACT_APP_PROD_URL}/get-all-status`,
            { stations: QCstations },
            { withCredentials: true }
          );
          setDataQC(responseQC.data);
          //Downloading ON deice status
          const responseON = await axios.post(
            process.env.NODE_ENV === "development" ? "http://localhost:3000/get-all-status" : `${process.env.REACT_APP_PROD_URL}/get-all-status`,
            { stations: ONstations },
            { withCredentials: true }
          );
          setDataON(responseON.data);
          //Downloading Fuel status
          const responseFuel = await axios.post(
            process.env.NODE_ENV === "development" ? "http://localhost:3000/get-all-fuel-status" : `${process.env.REACT_APP_PROD_URL}/get-all-fuel-status`,
            { stations: FuelStations },
            { withCredentials: true }
          );
          setDataFuelStatus(responseFuel.data);
          console.log("---- DEBUG STATUS.JS ----");
          for (let station of responseQC.data) {
            console.log(`Station ${station.station} deice status has been updated today ? ${isToday(new Date(station.createdAt))}`);
          }
          console.log("--------");
          for (let station of responseON.data) {
            console.log(`Station ${station.station} deice status has been updated today ? ${isToday(new Date(station.createdAt))}`);
          }
          console.log("--------");
          for (let station of responseFuel.data) {
            console.log(`Station ${station.station} fuel status has been updated today ? ${isToday(new Date(station.createdAt))}`);
          }
          console.log("--------");
        } else {
          setIsLoggedIn(false);
          navigate("/login");
        }
      } catch (error) {
        console.log(error);
        setIsLoggedIn(null);
      }
    })();
  }, [navigate]);

  return isLoggedIn === null ? (
    <Loading />
  ) : (
    <div className="flex flex-col w-screen h-screen bg-background ">
      <div>
        <TopNavBarStatusPage
          modalCallback={() =>
            setModalIsOpen((v) => {
              return !v;
            })
          }
          isStatusPage={true}
          title={"Status ON & QC"}
          userInfo={userInfo}
        />
      </div>
      <div className="shadow w-full h-full">
        <MapComponent dataQC={dataQC} dataON={dataON} dataFuel={dataFuelStatus} userInfo={userInfo} />
      </div>

      <DeiceStatusModal
        isOpen={modalIsOpen}
        closeCallback={() =>
          setModalIsOpen((v) => {
            return !v;
          })
        }
        dataQC={dataQC}
        dataON={dataON}
        dataFuel={dataFuelStatus}
        userInfo={userInfo}
      />
      {/* <CreatedBy /> */}
    </div>
  );
}

export default StatusPage;
