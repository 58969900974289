/**
 * Card component
 * @param {*} children - Body of the card
 * @param {String} cardTitle - Card title
 * @param {String} status - Ignored if null, zindex of the card expressed with tailwindcss classes, ex : z-50
 * @returns
 */
export const Card = ({ children, cardTitle, status }) => {
  return (
    <div className="w-full">
      {/* md:w-[600px] */}
      <div className="flex items-center">
        <div className="pb-2 pr-2 text-2xl font-bold tracking-tight text-gray-300">{cardTitle}</div>
        <div
          className={
            status === undefined
              ? "w-2 h-2 rounded-full bg-red-600 animate-pulse"
              : status === true
              ? "w-2 h-2 rounded-full bg-green-600"
              : status === false
              ? "w-2 h-2 rounded-full bg-red-600 animate-pulse"
              : status === null
              ? "w-0 h-0"
              : "w-2 h-2 rounded-full bg-red-600 animate-pulse"
          }
        ></div>
      </div>

      <div className="w-full bg-card rounded-xl border-line shadow-lg">
        <div className="block p-4 rounded-lg">{children}</div>
      </div>
    </div>
  );
}

export const LoginCard = ({ children, cardTitle }) => {
  return (
    <div className="w-96 mb-4">
      <div className="flex items-center">
        <div className="pl-1 pb-2 text-2xl font-bold tracking-tight text-gray-300">{cardTitle}</div>
      </div>

      <div className="w-full bg-card rounded-xl border-line shadow-lg ">
        <div className="block p-4 rounded-lg">{children}</div>
      </div>
    </div>
  );
}
