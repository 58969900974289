import { isToday } from "../utils/Date";
import { WEEKLY_CHECK_DAYNUMBER } from "../App";

const TODAY = new Date();

export const TableStatus = ({ data, type }) => {
  return (
    <table className="w-full xl:table-fixed">
      <thead>
        <tr>
          <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Station</th>
          <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">{type} Operational</th>
          <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">{type} Accessible</th>
          <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Deice Tank is Hot</th>
          <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Deice Pump Checked</th>
          <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Deice Tank Level</th>
          <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">{type} Tank Level</th>
          <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Fuel Tank Level</th>
          <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6 w-56">Comments</th>
          <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Reported By</th>
          <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6 w-60">Reported At</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((status, i) => {
          return (
            <tr className={i % 2 === 0 ? "bg-titleBar hover:bg-card" : "bg-titleBar hover:bg-card"} key={Math.random() * 10}>
              <td className="border-collapse text-gray-300 border border-line p-3" key={Math.random() * 100}>
                <div className="flex justify-center">
                  <div
                    className={
                      status.status & isToday(new Date(status?.createdAt))
                        ? "w-fit px-2 rounded-lg text-gray-100 bg-green-600 font-bold"
                        : "w-fit px-2 rounded-lg text-gray-100 bg-red-700 font-bold"
                    }
                  >
                    {status?.station}
                  </div>
                </div>
              </td>
              <td
                className={
                  status?.skyjackOperational === "No" || status?.skyjackOperational === undefined
                    ? "border-collapse border border-line p-3 text-red-700 font-bold"
                    : "border-collapse text-gray-300 border border-line p-3 "
                }
                key={Math.random() * 100}
              >
                {status?.skyjackOperational}
              </td>
              <td
                className={
                  status?.skyjackAccessible === "No" || status?.skyjackAccessible === undefined
                    ? "border-collapse  border border-line p-3 text-red-700 font-bold"
                    : "border-collapse text-gray-300 border border-line p-3 "
                }
                key={Math.random() * 100}
              >
                {status?.skyjackAccessible}
              </td>
              <td
                className={
                  status?.deiceTankHot === "No" || status?.deiceTankHot === undefined
                    ? "border-collapse  border border-line p-3 text-red-700 font-bold"
                    : "border-collapse text-gray-300 border border-line p-3 "
                }
                key={Math.random() * 100}
              >
                {status?.deiceTankHot}
              </td>
              <td
                className={
                  (TODAY.getDay() === WEEKLY_CHECK_DAYNUMBER) & (status?.deicePumpChecked === "No" || status?.deicePumpChecked === undefined)
                    ? "border-collapse  border border-line p-3 text-red-700 font-bold"
                    : "border-collapse text-gray-300 border border-line p-3 "
                }
                key={Math.random() * 100}
              >
                {status?.deicePumpChecked === undefined ? "N/A" : status?.deicePumpChecked}
              </td>
              <td
                className={
                  (TODAY.getDay() === WEEKLY_CHECK_DAYNUMBER) & (status?.deiceTankLevel === "1/4" || status?.deiceTankLevel === undefined)
                    ? "border-collapse  border border-line p-3 text-red-700 font-bold"
                    : "border-collapse text-gray-300 border border-line p-3 "
                }
                key={Math.random() * 100}
              >
                {status?.deiceTankLevel === undefined ? "N/A" : status?.deiceTankLevel}
              </td>
              <td
                className={
                  (TODAY.getDay() === WEEKLY_CHECK_DAYNUMBER) & (status?.skyjackTankLevel === "1/4" || status?.skyjackTankLevel === undefined)
                    ? "border-collapse 0 border border-line p-3 text-red-700 font-bold"
                    : "border-collapse text-gray-300 border border-line p-3 "
                }
                key={Math.random() * 100}
              >
                {status?.skyjackTankLevel === undefined ? "N/A" : status?.skyjackTankLevel}
              </td>
              <td className={"border-collapse text-gray-300 border border-line p-3 "} key={Math.random() * 100}>
                {status?.fuelLevel === undefined ? "-" : status?.fuelLevel}
              </td>
              <td className="border-collapse text-gray-300 border border-line p-3" key={Math.random() * 100}>
                {status?.comments}
              </td>
              <td className="border-collapse text-gray-300 border border-line p-3" key={Math.random() * 100}>
                {status?.reportedBy}
              </td>
              <td className="border-collapse text-gray-300 border border-line p-3" key={Math.random() * 100}>
                <div className={isToday(new Date(status.createdAt)) ? "" : " text-red-700 font-bold"}>{new Date(status?.createdAt).toLocaleString()}</div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const TableFuelStatus = ({ data, userInfo }) => {
  return userInfo?.admin ? (
    <div>
      <table className="w-full xl:table-fixed">
        <thead>
          <tr>
            <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Station</th>
            <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Clear & Bright Tested</th>
            <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Hose Inspected</th>
            <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Water Paste Tested</th>
            <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Cabinet Inspected</th>
            <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Top Storage Tank Clean</th>
            <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Deadman Servicable</th>
            <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6 w-56">Comments</th>
            <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6">Reported By</th>
            <th className=" p-2 sticky top-0 bg-card text-gray-300 pb-6 w-60">Reported At</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((status, i) => {
            return (
              <tr className={i % 2 === 0 ? "bg-titleBar hover:bg-card" : "bg-titleBar hover:bg-card"} key={Math.random() * 10}>
                <td className="border-collapse text-gray-300 border border-line p-3" key={Math.random() * 100}>
                  <div className="flex justify-center">
                    <div
                      className={
                        status.status & isToday(new Date(status?.createdAt))
                          ? "w-fit px-2 rounded-lg text-gray-100 bg-green-600 font-bold "
                          : "w-fit px-2 rounded-lg text-gray-100 bg-red-700 font-bold "
                      }
                    >
                      {status?.station}
                    </div>
                  </div>
                </td>
                <td
                  className={
                    status?.clearBrightTest === "No" || status?.clearBrightTest === undefined
                      ? "border-collapse border border-line p-3 text-red-700 font-bold"
                      : "border-collapse text-gray-300 border border-line p-3 "
                  }
                  key={Math.random() * 100}
                >
                  {status?.clearBrightTest}
                </td>
                <td
                  className={
                    status?.hoseInspected === "No" || status?.hoseInspected === undefined
                      ? "border-collapse  border border-line p-3 text-red-700 font-bold"
                      : "border-collapse text-gray-300 border border-line p-3 "
                  }
                  key={Math.random() * 100}
                >
                  {status?.hoseInspected}
                </td>
                <td
                  className={
                    status?.waterPasteTest === "No" || status?.waterPasteTest === undefined
                      ? "border-collapse  border border-line p-3 text-red-700 font-bold"
                      : "border-collapse text-gray-300 border border-line p-3 "
                  }
                  key={Math.random() * 100}
                >
                  {status?.waterPasteTest}
                </td>
                <td
                  className={
                    status?.cabinetInspected === "No" || status?.cabinetInspected === undefined
                      ? "border-collapse  border border-line p-3 text-red-700 font-bold"
                      : "border-collapse text-gray-300 border border-line p-3 "
                  }
                  key={Math.random() * 100}
                >
                  {status?.cabinetInspected}
                </td>
                <td
                  className={
                    status?.topStorageTankClean === "No" || status?.topStorageTankClean === undefined
                      ? "border-collapse  border border-line p-3 text-red-700 font-bold"
                      : "border-collapse text-gray-300 border border-line p-3 "
                  }
                  key={Math.random() * 100}
                >
                  {status?.topStorageTankClean}
                </td>
                <td
                  className={
                    status?.deadmanServicable === "No" || status?.deadmanServicable === undefined
                      ? "border-collapse  border border-line p-3 text-red-700 font-bold"
                      : "border-collapse text-gray-300 border border-line p-3 "
                  }
                  key={Math.random() * 100}
                >
                  {status?.deadmanServicable}
                </td>
                <td className="border-collapse text-gray-300 border border-line p-3" key={Math.random() * 100}>
                  {status?.comments}
                </td>
                <td className="border-collapse text-gray-300 border border-line p-3" key={Math.random() * 100}>
                  {status?.reportedBy}
                </td>
                <td className="border-collapse text-gray-300 border border-line p-3" key={Math.random() * 100}>
                  <div className={isToday(new Date(status.createdAt)) ? "" : " text-red-700 font-bold"}>{new Date(status?.createdAt).toLocaleString()}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : null;
};
